<template>
  <div>
    <v-card
      flat
      rounded="lg"
      class="datatable-card-border"
    >
      <v-card-text>
        <v-row class="mb-3">
          <v-col
            cols="12"
            sm="7"
            md="4"
            lg="3"
            xl="3"
          >
            <v-text-field
              v-model="searchValue"
              :label="$t('Search...')"
              prepend-inner-icon="mdil-magnify"
              outlined
              dense
              clearable
              single-line
              hide-details=""
              autocomplete="off"
              class="small-input"
              @keydown.enter="handleSearch()"
            />
          </v-col>

          <v-col class="d-flex">
            <v-btn
              color="primary"
              small
              depressed
              @click="handleSearch()"
            >
              <v-icon left>
                mdil-magnify
              </v-icon>
              {{ $t('Search') }}
            </v-btn>

            <v-btn
              class="mx-2"
              :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
              small
              depressed
              @click="resetTableData = true"
            >
              <v-icon left>
                mdil-refresh
              </v-icon>
              {{ $t('Reset') }}
            </v-btn>
          </v-col>

          <v-col
            v-if="hasAddNew && $_has_perm(addPermission)"
            class="d-flex justify-end"
          >
            <div class="col col-sm-3 col-md-6 col-lg-4 pa-0">
              <v-select
                v-model="selectedHeaders"
                :items="headers"
                return-object
                multiple
                dense
                solo
                chips
                flat
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3' "
                single-line
                hide-details
                prepend-inner-icon="mdil-table"
                class="has-prepend-inner small-input"
                :menu-props="{'offset-y': true}"
              >
                <template v-slot:selection="{index}">
                  <span v-if="index === 0">
                    {{ selectedHeaders.length }}
                    {{ $t('of') }}
                    {{ headers.length }}
                  </span>
                </template>
              </v-select>
            </div>

            <v-btn
              class="ms-2"
              color="primary"
              small
              depressed
              @click="$emit('add-new')"
            >
              <v-icon>
                mdil-plus
              </v-icon>
              {{ $t('Add New') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :headers="visibleHeaders"
          :items="tableData.results"
          :loading="loadingTable"
          :server-items-length="tableData.count"
          :items-per-page.sync="queryParams.page_size"
          :no-results-text="$t('No results found')"
          :no-data-text="$t('No results found')"
          :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
          @update:sort-by="handelChangeSort"
          @update:sort-desc="handelChangeSortDir"
          @update:items-per-page="handelChangePerPage"
          @update:page="handelChangePage"
        >
          <template
            v-for="(slotName, index) in tableSlots"
            v-slot:[`item.${slotName}`]="{item}"
          >
            <div :key="index">
              <slot
                :name="slotName"
                :item="item"
              />
            </div>
          </template>
        </v-data-table>

        <!-- <app-pagination
          v-if="!loadingTable"
          :mini="true"
          :query-params="queryParams"
          :total-records="tableData.count"
        /> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    headers: { type: Array, default: () => [] },
    endPoint: { type: String, default: '' },
    hasAddNew: { type: Boolean, default: true },
    addPermission: { type: String, default: 'abc' },
    resetTableData: { type: Boolean, default: false },
    updateTableData: { type: Boolean, default: false },
    tableSlots: { type: Array, default: () => [] },
  },

  data() {
    return {
      selectedHeaders: [],
      searchValue: '',
      loadingTable: true,
      tableData: {},
      totalRecords: 0,
      queryParams: {
        ordering: '-id',
        page: 1,
        page_size: 10,
        search: '',
      }
    }
  },

  computed: {
    visibleHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },

  watch: {
    queryParams: {
      immediate: true,
      deep: true,
      handler() {
        this.getTableData()
      }
    },

    resetTableData: {
      immediate: false,
      handler() {
        if (this.resetTableData) {
          this.queryParams = {
            ordering: '-id',
            page: 1,
            page_size: 10,
            search: '',
          }
        }
      }
    },

    updateTableData: {
      immediate: false,
      handler() {
        if (this.updateTableData) {
          this.getTableData()
        }
      }
    },

    searchValue: {
      immediate: false,
      handler() {
        if (!this.searchValue || this.searchValue.length < 1) {
          this.handleSearch()
        }
      }
    },
  },

  mounted() {
    this.selectedHeaders = this.headers
  },

  methods: {
    getTableData() {
      this.loadingTable = true
      axios.get(this.endPoint, { params: this.queryParams }).then(res => {
        this.tableData = res.data
        this.$emit('data-loaded')
      }).finally(() => {
        this.loadingTable = false
      })
    },

    handelChangeSort(value) {
      this.queryParams.ordering = value[0]
    },

    handelChangeSortDir(value) {
      if (value[0]) {
        this.queryParams.ordering = `-${this.queryParams.ordering}`
      } else {
        this.queryParams.ordering = `${this.queryParams.ordering}`
      }
    },

    handleSearch() {
      this.queryParams.search = this.searchValue
    },

    handelChangePage(page) {
      this.queryParams.page = page
    },

    handelChangePerPage(perPage) {
      this.queryParams.page_size = perPage
    },
  },
}
</script>

<style lang="scss" scoped>
</style>