<template>
  <span
    class="material-symbols-rounded"
    :style="`font-size: ${size}px; color: ${color}; font-variation-settings: 'FILL' ${fill}, 'wght' ${weight}, 'GRAD' 1, 'opsz' ${size}`"
  >
    {{ icon }}
  </span>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: '' },
    size: { type: String, default: '24' },
    fill: { type: String, default: '0' },
    weight: { type: String, default: '200' },
    color: { type: String, default: '' },
  },
}
</script>

<style lang="scss" scoped></style>
