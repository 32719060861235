import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import auth from './modules/auth'
import notification from './modules/notification'
import bank from './modules/bank'
import company from './modules/company'
import companyType from './modules/companyType'
import governorate from './modules/governorate'
import shareholderType from './modules/shareholderType'
import shareholder from './modules/shareholder'
import attachmentType from './modules/attachmentType'
import expenseType from './modules/expenseType'

export default new Vuex.Store({
  modules: {
    auth,
    notification,
    bank,
    company,
    companyType,
    governorate,
    shareholderType,
    shareholder,
    attachmentType,
    expenseType,
  },

  plugins: [
    createPersistedState({
      paths: ['auth']
    })
  ]
})
