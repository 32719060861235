import axios from 'axios'

const state = {
  shareholdersList: [],
  authShareholder: {},
}

const getters = {
  shareholdersList: state => state.shareholdersList,

  authShareholder: state => state.authShareholder,
}

const actions = {
  async getShareholdersList({ commit }) {
    await axios.get('shareholder/all/').then(response => {
      commit('SET_SHAREHOLDER_LIST', response.data)
    })
  },
  
  async getAuthShareholder({ commit }) {
    await axios.get('shareholder/me/').then(response => {
      commit('SET_AUTH_SHAREHOLDER', response.data)
    })
  },
}

const mutations = {
  SET_SHAREHOLDER_LIST: (state, data) => {
    state.shareholdersList = data
  },
  
  SET_AUTH_SHAREHOLDER: (state, data) => {
    state.authShareholder = data
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
