<template>
  <div
    v-if="showImageUploader"
    class="d-flex"
    :class="parentClasses"
    :style="parentStyle"
  >
    <input
      ref="appMainImage"
      type="file"
      accept="image/*"
      style="
        visibility: hidden;
        width: 0;
        position: absolute;
        top: -9999px;
      "
      @input="imageSelected()"
    >
    <div
      class="image-upload"
      :class="classes"
      :style="{
        backgroundImage: imageUrl && showImage ? `url(${imageUrl})` : '',
        width: width,
        height: height,
      }"
      @click="chooseImage()"
    >
      <div
        :class="imageUrl && showImage ? 'd-none' : ''"
        class="image-upload-icon text-center"
        :style="{width: width}"
      >
        <v-icon class="d-block">
          mdil-cloud-upload
        </v-icon>
        <span class="d-block text-caption text--secondary">
          {{ $t(label) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: { type: String, default: '200px' },
    height: { type: String, default: '200px' },
    classes: { type: String, default: 'rounded-lg' },
    parentClasses: { type: String, default: '' },
    parentStyle: { type: String, default: '' },
    label: { type: String, default: 'Upload image' },
    parentImageUrl: { type: String, default: null },
    showImage: { type: Boolean, default: true },
  },

  data() {
    return {
      showImageUploader: false,
      imageUrl: null,
    }
  },

  mounted() {
    if (this.parentImageUrl && this.parentImageUrl.length) {
      if (this.parentImageUrl.startsWith('/media')) {
        this.imageUrl = `${this.$_file_path()}${this.parentImageUrl}?token=${this.$_auth_token()}`
      } else if (this.parentImageUrl.startsWith('http')) {
        this.imageUrl = `${this.parentImageUrl}?token=${this.$_auth_token()}`
      }
    }

    this.showImageUploader = true
  },

  methods: {
    clearImage() {
      this.$refs.appMainImage.value = null
      this.imageUrl = null
    },

    chooseImage() {
      this.$refs.appMainImage.click()
    },

    imageSelected() {
      let imageFile = this.$refs.appMainImage.files[0]

      if (imageFile.type.includes('image/')) {
        this.$emit('image-selected', imageFile)
        this.showImageUploader = false
        this.imageUrl = URL.createObjectURL(imageFile)
        this.$nextTick(() => {
          this.showImageUploader = true
        })
        this.$emit('is-image-updated', true)
      } else {
        this.$_notify('File type must be an image', 'error')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>