import axios from 'axios'

const state = {
  shareholderTypesList: [],
}

const getters = {
  shareholderTypesList: state => state.shareholderTypesList,
}

const actions = {
  async getShareholderTypesList({ commit }) {
    await axios.get('shareholder-type/all/').then(response => {
      commit('SET_SHAREHOLDER_TYPE_LIST', response.data)
    })
  },
}

const mutations = {
  SET_SHAREHOLDER_TYPE_LIST: (state, data) => {
    state.shareholderTypesList = data.map(b => {
      return { 'text': b.name, 'value': b.id }
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
