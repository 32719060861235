import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css';
import '@mdi/light-font/css/materialdesignicons-light.css';
import '@fortawesome/fontawesome-free/css/all.css'
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: true,
  icons: {
    iconfont: 'mdi' || 'mdil' || 'fa'
  },
  theme: {
    themes: {
      light: {
        primary: '#3B6196',
        altuny: '#C8A468',
      },
      dark: {
        primary: '#3B6196',
        altuny: '#C8A468',
      },
    }
  }
})
