import axios from 'axios'

const state = {
  accessToken: null,
  authUserData: {},
  authUserPermissions: [],
  isLoggedIn: false,
  rtl: true,
  themeIsDark: false,
  locale: 'ckb',
  sectionTitle: {},
}

const getters = {
  authUserData: state => state.authUserData,

  accessToken: state => state.accessToken,

  authUserPermissions: state => state.authUserPermissions,

  isLoggedIn: state => state.isLoggedIn,

  rtl: state => state.rtl,

  locale: state => state.locale,

  themeIsDark: state => state.themeIsDark,

  sectionTitle: state => state.sectionTitle
}

const actions = {
  async login({ commit }, payload) {
    await axios.post('login/', payload).then(response => {
      commit('SET_LOGIN', response.data)
    })
  },

  async clientLogin({ commit }, payload) {
    await axios.post('client/login/', payload).then(response => {
      commit('SET_LOGIN', response.data)
    })
  },

  async getAuthUserData({ commit }) {
    await axios.get('user/me/').then(response => {
      commit('SET_AUTH_USER_DATA', response.data)
    })
  },

  async getAuthUserPermission({ commit }) {
    await axios.get('user/auth_user_permissions/').then(response => {
      commit('SET_AUTH_USER_PERMISSION', response.data)
    })
  },

  async logout({ commit },) {
    await axios.post('logout/').then(() => {
      commit('SET_LOGOUT')
    })
  },

  async setLocale({ commit }, data) {
    commit('SET_LOCALE', data)
  },

  async toggleRtl({ commit }, data) {
    commit('TOGGLE_RTL', data)
  },

  async toggleTheme({ commit }, data) {
    commit('TOGGLE_THEME', data)
  },

  async setSectionTitle({ commit }, data) {
    commit('SET_SECTION_TITLE', data)
  },
}

const mutations = {
  SET_LOGIN: (state, data) => {
    state.accessToken = data.token
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
    state.authUserData = data.user
    state.isLoggedIn = true
  },

  SET_AUTH_USER_DATA: (state, data) => {
    state.authUserData = data
    state.isLoggedIn = true
  },

  SET_AUTH_USER_PERMISSION: (state, data) => {
    state.authUserPermissions = data
  },

  SET_LOGOUT: (state) => {
    state.accessToken = null
    state.authUserData = {}
    state.isLoggedIn = false
    state.authUserPermissions = []
    localStorage.removeItem('vuex')
    delete axios.defaults.headers.common['Authorization']
  },

  TOGGLE_RTL: (state, data) => {
    state.rtl = data
  },

  TOGGLE_THEME: (state, data) => {
    state.themeIsDark = data
  },

  SET_LOCALE: (state, data) => {
    state.locale = data
  },

  SET_SECTION_TITLE: (state, data) => {
    state.sectionTitle = data
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
