import Vue from 'vue'
import AppDatatable from '@/components/Global/AppDatatable.vue'
import AppPagination from '@/components/Global/AppPagination.vue'
import AppFormTip from '@/components/Global/AppFormTip.vue'
import AppDatePicker from '@/components/Global/AppDatePicker.vue'
import AppAddEditAttachment from '@/components/Global/AppAddEditAttachment.vue'
import AppImageUploader from '@/components/Global/AppImageUploader.vue'
import InvoiceHeader from '@/components/Global/InvoiceHeader.vue'
import AppIcon from '@/components/Global/AppIcon.vue'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField

Vue.component('v-text-field', VTextField)
Vue.component('app-datatable', AppDatatable)
Vue.component('app-pagination', AppPagination)
Vue.component('app-form-tip', AppFormTip)
Vue.component('app-date-picker', AppDatePicker)
Vue.component('app-add-edit-attachment', AppAddEditAttachment)
Vue.component('app-image-uploader', AppImageUploader)
Vue.component('invoice-header', InvoiceHeader)
Vue.component('app-icon', AppIcon)

Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: { min: 0, max: 3 },
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: null,
  valueAsInteger: false,
  allowNegative: true,
})
