<template>
  <v-app>
    <notification />

    <router-view />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Notification from './components/Global/Notification.vue'
import axios from 'axios'

export default {
  name: 'App',

  components: {
    Notification,
  },

  data() {
    return {
      drawer: true,
    };
  },

  computed: {
    ...mapGetters(['isLoggedIn']),
  },

  beforeMount() {
    if (this.isLoggedIn) {
      const vuex = JSON.parse(localStorage.getItem('vuex'))
      this.$vuetify.rtl = vuex.auth.rtl
      this.$vuetify.theme.dark = vuex.auth.themeIsDark
      this.$i18n.locale = vuex.auth.locale
      axios.defaults.headers.common['Authorization'] = `Bearer ${vuex.auth.accessToken}`

      this.getAuthUserData()
    }
  },

  mounted() {
    const appWrap = document.querySelector('.v-application--wrap')
    appWrap.classList.add('app-background')
  },

  methods: {
    ...mapActions([
      'getAuthUserData',
    ]),
  },
}
</script>
<style lang="scss">
@import "../public/style.scss";

.slideLeft-enter-active,
.slideLeft-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.slideLeft-enter,
.slideLeft-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}

.slideRight-enter-active,
.slideRight-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.slideRight-enter,
.slideRight-leave-to {
  opacity: 0;
  transform: translateX(30%);
}
</style>
