<template>
  <div>
    <v-dialog
      v-model="confirmDialog"
      persistent
      transition="slide-y-transition"
      max-width="400"
    >
      <v-card
        v-if="propObject"
        class="dialog-card-border"
        rounded="lg"
      >
        <v-card-title
          class="text-h5"
          :class="propObject.titleColor"
        >
          {{ $t(propObject.title) }}
        </v-card-title>

        <v-card-text style="font-size: 16px">
          {{ $t(propObject.message) }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            class="mx-2"
            @click="$emit('close', false)"
          >
            {{ $t(propObject.cancelBtnText) }}
          </v-btn>

          <v-btn
            :color="propObject.confirmBtnColor"
            depressed
            small
            @click="$emit('close', true)"
          >
            <v-icon
              v-if="propObject.confirmBtnIcon.length > 0"
              small
              left
            >
              {{ propObject.confirmBtnIcon }}
            </v-icon>

            {{ $t(propObject.confirmBtnText) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Confirmation',

  props: {
    confirmDialog: { type: Boolean, default: true },
    propObject: {
      type: Object, default: () => { }
    }
  },
}
</script>

<style lang="scss" scoped></style>
