<template>
  <div>
    <v-row class="mt-3">
      <v-col class="text-caption text-end">
        <div class="pt-4">
          {{ $t('Total Records') }} {{ totalRecords }}
        </div>
      </v-col>
      <v-col
        cols="4"
        sm="2"
        md="2"
        lg="1"
      >
        <v-select
          v-model="queryParams.page_size"
          dense
          :items="pageSizeOptions"
          single-line
          hide-details
          :menu-props="{'offset-y': true}"
          class="small-input"
        />
      </v-col>
      <v-col
        cols="12"
        sm="auto"
        class="d-flex justify-end"
      >
        <v-pagination
          v-model="queryParams.page"
          total-visible="7"
          small
          :length="totalPages"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    props: {
      queryParams: {
        type: Object, required: true
      },

      totalRecords: {
        type: Number, required: true
      },

      mini: {
        type: Boolean, default: false
      },
    },

    data() {
      return {
        pageSizeOptions: [
          { text: '10', value: 10 },
          { text: '25', value: 25 },
          { text: '50', value: 50 },
          { text: '100', value: 100 },
        ]
      };
    },

    computed: {
      totalPages() {
        return Math.ceil(this.totalRecords / this.queryParams.page_size)
      }
    },
  };
</script>

<style lang="scss" scoped></style>
