import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/components/Layout/Login.vue')
  },
  {
    path: '/client',
    name: 'client',
    component: () => import('@/components/ClientPanel/ClientPanel.vue'),
    children: [
      {
        path: 'my-company',
        name: 'my-company',
        component: () => import('@/components/ClientPanel/Company/MyCompany.vue')
      },
      {
        path: 'profile',
        name: 'client-profile',
        component: () => import('@/components/ClientPanel/Profile/ClientProfile.vue')
      },
    ]
  },

  // admin panel routes
  {
    path: '/admin-login',
    name: 'admin-login',
    component: () => import('@/components/Layout/AdminLogin.vue')
  },
  {
    path: '/admin-panel',
    name: 'admin-panel',
    component: () => import('@/components/AdminPanel/AdminPanel.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/components/AdminPanel/Dashboard.vue')
      },
      {
        path: 'company',
        name: 'company',
        component: () => import('@/components/AdminPanel/Company/Companies.vue')
      },
      {
        path: 'company/:id',
        name: 'view-company',
        params: { id: 0 },
        component: () => import('@/components/AdminPanel/Company/View.vue'),
        children: [
          {
            path: '',
            name: 'view-company.info',
            component: () => import('@/components/AdminPanel/Company/Info.vue'),
          },
          {
            path: 'shareholders',
            name: 'view-company.shareholders',
            component: () => import('@/components/AdminPanel/Company/CompanyShareholders.vue'),
          },
          {
            path: 'accounting',
            name: 'view-company.accounting',
            component: () => import('@/components/AdminPanel/Company/Accounting/CompanyAccounting.vue'),
          },
          {
            path: 'attachment',
            name: 'view-company.attachment',
            component: () => import('@/components/AdminPanel/Company/CompanyAttachments.vue'),
          },
          {
            path: 'seasonal-form',
            name: 'view-company.seasonal-form',
            component: () => import('@/components/AdminPanel/Company/SeasonalForm/SeasonalForms.vue'),
          },
          {
            path: 'residence',
            name: 'view-company.residence',
            component: () => import('@/components/AdminPanel/Company/Residence/Residence.vue'),
          },
          {
            path: 'social-security',
            name: 'view-company.social-security',
            component: () => import('@/components/AdminPanel/Company/SocialSecurity/SocialSecurity.vue'),
          },
          {
            path: 'lawyer-contract',
            name: 'view-company.lawyer-contract',
            component: () => import('@/components/AdminPanel/Company/LawyerContract/LawyerContract.vue'),
          },
        ]
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/components/AdminPanel/User/Users.vue')
      },
      {
        path: 'profile',
        name: 'admin-profile',
        component: () => import('@/components/AdminPanel/User/Profile.vue')
      },
      {
        path: 'shareholder',
        name: 'shareholder',
        component: () => import('@/components/AdminPanel/Company/Shareholder/Shareholders.vue')
      },
      {
        path: 'shareholder/:id',
        name: 'view-shareholder',
        params: { id: 0 },
        component: () => import('@/components/AdminPanel/Company/Shareholder/View.vue')
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
